import { useEffect } from "react";
import useStore from "../store/useStore";
import getBreakpoint from "../utils/getBreakpoint";

const useGetBreakpoint = () => {
    const setBreakpoint = useStore(state => state.setBreakpoint)
    
    useEffect(() => {
        const handleResize = () => {
            setBreakpoint(getBreakpoint())
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setBreakpoint]);
}

export default useGetBreakpoint;