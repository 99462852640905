import { UseCanvas } from "@14islands/r3f-scroll-rig"
import { StickyScrollScene } from '@14islands/r3f-scroll-rig/powerups'
import { useGLTF, useTexture } from "@react-three/drei"
import { useMemo, useRef } from "react"
import sortByName from "../../utils/sortByName"
import Screen from "./Screen"
import routes from "../../config/routes"
import Button from "../../components/Button"
import { SRGBColorSpace } from 'three'
import { useFrame, useThree } from "@react-three/fiber"
import { animated, easings, useSpring } from "@react-spring/three"
import useStore from "../../store/useStore"
import { useControls } from "leva"
import styles from './projectst.module.scss'
import { findProjectBySlug } from "../../utils/findProjectBySlug"
import { useShallow } from "zustand/react/shallow"
import useTransitionToLocation from '../../hooks/useTransitionToLocation'
import * as THREE from 'three'
import { easing } from 'maath'
import { useInView, animated as animatedWeb } from '@react-spring/web'

// const AnimatedMeshReflectorMaterial = animated(MeshReflectorMaterial)

useGLTF.preload('/porfolio_projects_nature_test.glb')
useTexture.preload('/baked_nature_test.jpg')
useTexture.preload('/alpha.jpg')

const Model = ({ scale, scrollState, inViewport }) => {
    const groupRef = useRef()
    const { camera, viewport } = useThree()
    const { nodes } = useGLTF('/porfolio_projects_nature_test.glb')
    const { computersTexture, floorTexture } = useTexture({
        computersTexture: '/baked_nature_test.jpg',
        floorTexture: '/alpha.jpg'
    })
    computersTexture.flipY = false
    computersTexture.colorSpace = SRGBColorSpace

    const screens = useMemo(() => Object.values(nodes).filter(value => value.name.includes("screen")).sort(sortByName), [nodes]) 

    const screensRef = useRef([])

    const { projects, setActiveProjectSlug } = useStore(
        useShallow(state => ({
            projects: state.projects,
            activeProjectSlug: state.activeProjectSlug,
            setActiveProjectSlug: state.setActiveProjectSlug,
            prevLocation: state.prevLocation,
            transitionToLocation: state.transitionToLocation,
            breakpoint: state.breakpoint,
            setIsAmbientAudioActive: state.setIsAmbientAudioActive
        }))
    )

    const { shouldTransition }  = useTransitionToLocation()

    const { mobileFloorColor } = useControls('Projects', {
        reflectionColor: '#073d45',
        sparklesColor: '#144e4c',
        mobileFloorColor: '#213848',
        roughness: 0.1
    })

    const { opacity } = useSpring({
        opacity: inViewport && !shouldTransition ? 1 : 0,
        // config: inViewport ? config.wobbly : config.stiff,
        // delay: inViewport ? 100 : 0,
        config: { 
            duration: 1000,
            easing: easings.easeInOutCubic
        }
    })

    useFrame((_, dt) => {
        const index = Math.min(Math.floor(scrollState.progress * 5), 4)
    
        if(inViewport && projects.length > 0 && index >= 0 && scrollState.progress > 0.1) {
            setActiveProjectSlug(projects[index].slug)
            const quaternion = new THREE.Quaternion().setFromRotationMatrix(screensRef.current[index].matrix).invert()

            const screenPosition = new THREE.Vector3()
            screensRef.current[index].getWorldPosition(screenPosition)
           
            const groupPosition = new THREE.Vector3()
            groupRef.current.getWorldPosition(groupPosition)
            const cameraPosition = new THREE.Vector3()
            camera.getWorldPosition(cameraPosition)
    
            const direction = new THREE.Vector3().subVectors(cameraPosition, screenPosition)
            const distance = shouldTransition ? -viewport.height * 0.1 : -viewport.height * 0.5
            direction.add(new THREE.Vector3(0, 0, distance))
            const newPosition = new THREE.Vector3().addVectors(groupPosition, direction)

            easing.damp3(groupRef.current.position, newPosition, 0.6, dt)
            easing.dampQ(groupRef.current.quaternion, quaternion, 0.6, dt)
        }
    })

    return (
    <animated.group scale={scale.xy.min() * 0.3} ref={groupRef}>
        {projects.length && (
            <>
                <animated.mesh geometry={ nodes.computers.geometry }>
                    <animated.meshBasicMaterial map={ computersTexture } opacity={opacity} transparent />
                </animated.mesh>
                {/* Floor */}
                <mesh rotation={[-Math.PI / 2, 0, 0]}>
                    <circleGeometry args={[2.5, 32]}/>
                    <animated.meshStandardMaterial alphaMap={floorTexture} color={mobileFloorColor} side={THREE.DoubleSide} opacity={opacity} transparent />
                </mesh>
                {screens.map((mesh, index) => (
                    <Screen
                        ref={el => screensRef.current.push(el)}
                        key={projects[index].slug}
                        name={projects[index].slug}
                        featuredVideo={projects[index].featuredVideo?.fields.file.url} 
                        mesh={mesh}
                    />
                ))}
            </>
        )}
    </animated.group>
    )
}

const Projects = () => {
    const el = useRef()
    const activeProjectSlug = useStore(state => state.activeProjectSlug)
    const projects = useStore((state) => state.projects)
    const project = findProjectBySlug(projects, activeProjectSlug)
    const { setTransitionToLocation } = useTransitionToLocation()
    const [ref, springs] = useInView(() => ({
        from: {
          opacity: 0
        },
        to: {
          opacity: 1
        },
        config: {
            duration: 500
        }
      }))

    return (
        <section id="projects" className={styles.projects}>
            <div className={styles.stickyContainer}>
                <div ref={el} className={styles.someDomContent}>
                    <animatedWeb.div className={styles.info} ref={ref} style={springs}>
                        <h1 className={styles.projectName}>{project?.title}</h1>
                        <Button onClick={() => setTransitionToLocation(routes.project.url + activeProjectSlug)}>View Project</Button>
                    </animatedWeb.div>
                </div>
            </div>
            <UseCanvas>
                <StickyScrollScene track={el}>
                    {(props) => (
                        <Model {...props} />
                    )}
                </StickyScrollScene>
            </UseCanvas>
        </section>
    )
}

export default Projects