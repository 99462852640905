import { useLocation } from "wouter"
import useStore from "../store/useStore"
import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

const useTranstionToLocation = (callback = () => {}, timeout = 2000) => {
    const [location, setLocation] = useLocation()
    const { transitionToLocation, setTransitionToLocation, setPrevLocation } = useStore(
        useShallow(state => ({
            transitionToLocation: state.transitionToLocation,
            setTransitionToLocation: state.setTransitionToLocation,
            setPrevLocation: state.setPrevLocation,
        }))
    )
    const shouldTransition = !!transitionToLocation && location !== transitionToLocation

    useEffect(() => {
        if(shouldTransition) {
          callback()
          setTimeout(() => {
                setPrevLocation(location)
                setTransitionToLocation(null)
                setLocation(transitionToLocation)
          }, timeout);
        } 
      }, [shouldTransition, callback, timeout, setPrevLocation, setTransitionToLocation, setLocation, location, transitionToLocation])
    
    return {
        shouldTransition,
        setTransitionToLocation
    }
}

export default useTranstionToLocation