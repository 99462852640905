import { forwardRef, useEffect } from "react"
import { useLocation } from "wouter"
import { useScrollbar } from "@14islands/r3f-scroll-rig"

const ScrollRestoration = forwardRef((_, ref) => {
    const [location] = useLocation()
    const { scrollTo } = useScrollbar()

    useEffect(() => {
        const id = location.replace('/', '#')
        if(id !== '#' ) scrollTo(id,  { immediate: true })
        else scrollTo(0, { immediate: true })
    }, [location, scrollTo])

    return null
})

export default ScrollRestoration
