import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const RichText = ({ content, className }) => {
  const document = { nodeType: 'document', content }
  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { contentType, url } = node.data.target.fields.file
        const { id } = node.data.target.sys

        switch (contentType) {
          case 'image/jpeg':
            return (
              <img id={id} src={url} alt={id} />
            )
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: node => {
        const title = node.content.length > 0 ? node.content[0].value : null
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {title}
          </a>
        )
      }
    }
  }

  return (
    <section className={className}>
      {documentToReactComponents(document, renderOptions)}
    </section>
  )
}

export default RichText
