import { create } from 'zustand'

const useStore = create(set => ({
    projects: [],
    setProjects: (projects) => set(() => ({ projects })),
    breakpoint: '',
    setBreakpoint: (breakpoint) => set(() => ({ breakpoint })),
    isSoundMuted: true,
    setIsSoundMuted: (isSoundMuted) => set(() => ({ isSoundMuted })),
    isAmbientAudioActive: true,
    setIsAmbientAudioActive: (isAmbientAudioActive) => set(() => ({ isAmbientAudioActive })),
    activeProjectSlug: '',
    setActiveProjectSlug: (activeProjectSlug) => set(() => ({ activeProjectSlug })),
    prevLocation: '',
    setPrevLocation: (prevLocation) => set(() => ({ prevLocation })),
    transitionToLocation: '',
    setTransitionToLocation: (transitionToLocation) => set(() => ({ transitionToLocation })) 
}))

export const useIsMobile = () => {
    const breakpoint = useStore((state) => state.breakpoint)

    switch (breakpoint) {
        case 'xsmall':
        case 'small':
        case 'small-landscape':
        case 'medium-landscape':
            return true
        default:
            return false;
    }
}

export default useStore