import styles from './footer.module.scss'
import classnames from 'classnames'

const Footer = () => {
    return (
        <footer className={classnames(styles.footer)}>
            <a href="mailto:hello@ramsessalas.com" target="_blank" rel="noreferrer">contact@ramsessalas.com</a>
            <ul className={styles.socialMedia}>
                <li><a href="https://www.linkedin.com/in/ramjsm/" target="_blank" rel="noreferrer">Linkedin</a></li>
                <li><a href="https://github.com/ramjsm" target="_blank" rel="noreferrer">GitHub</a></li>
                <li><a href="https://www.instagram.com/ramjsm/" target="_blank" rel="noreferrer">Instagram</a></li>
               {/*  <li><a href="mailto:hello@ramsessalas.com" target="_blank" rel="noreferrer">Behance</a></li> */}
            </ul>
        </footer>
    )
}

export default Footer