import { shaderMaterial } from "@react-three/drei";
import { Texture } from "three";

export const ScreenShaderMaterial = shaderMaterial(
    {
        uTexture: new Texture(),
        uTime: 0,
        uOpacity: 0
    },
    `
      precision mediump float;
      varying vec2 vUv;
      varying vec3 vPosition;
      varying vec3 vNormal;

      void main() {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        gl_Position = projectedPosition;

        vec4 modelNormal = modelMatrix * vec4(normal, 0.0);
        
        // Varying
        vUv = uv;
        vPosition = modelPosition.xyz;
        vNormal = modelNormal.xyz;
      }
    `,
    `
      uniform sampler2D uTexture;
      uniform float uTime;
      uniform float uOpacity;
      varying vec2 vUv;
      varying vec3 vPosition;
      varying vec3 vNormal;
  
      void main() {
        #include <tonemapping_fragment>
        #include <colorspace_fragment>

        // Normal
        vec3 normal = normalize(vNormal);
        
        // Image
        vec4 textureColor = texture2D(uTexture, vUv);

        // Stripes
        float stripes = mod((vUv.y + uTime * 0.1) * 20.0, 1.0);
        stripes = pow(stripes, 1.0);

        // Fresnel
        vec3 viewDirection = normalize(vPosition - cameraPosition);
        float fresnel = dot(viewDirection, normal) + 0.1;
        fresnel = pow(fresnel, 2.0);

        // Falloff
        float falloff = smoothstep(0.3, 0.0, fresnel);
        
        // Holographic
        float holographic = stripes * fresnel;
        holographic += fresnel * 1.5;


        gl_FragColor = textureColor * vec4(uOpacity, uOpacity, uOpacity, holographic);
      }
    `
)

export default ScreenShaderMaterial