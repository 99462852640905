import { Environment, Float, MeshTransmissionMaterial , useGLTF } from '@react-three/drei'
import styles from './contact.module.scss'
import { useCallback, useMemo, useRef, useState } from 'react'
import { ScrollScene, UseCanvas } from '@14islands/r3f-scroll-rig'
import { useControls } from 'leva'
import { useFrame, useThree } from '@react-three/fiber'
import { animated, easings, useSpring } from '@react-spring/three'
import classNames from 'classnames'
import useTranstionToLocation from '../../hooks/useTransitionToLocation'
import useAudioWithFade from '../../hooks/useAudioWithFade'
import useStore, { useIsMobile } from '../../store/useStore'
import { useShallow } from 'zustand/react/shallow'
import Footer from '../../components/Footer'


useGLTF.preload('/energy_crystal.glb')

const AnimatedMeshTransmissionMaterial = animated(MeshTransmissionMaterial)

const Crystal = ({ scale, inViewport }) => {
    const { viewport } = useThree()
    const { nodes } = useGLTF('/energy_crystal.glb')
    const crystal = useRef()
    const [isRotating, setIsRotating] = useState(false)
    const { audio, fadeIn, fadeOut } = useAudioWithFade('/crystal.mp3', 500, 0.8)
    const { isSoundMuted, breakpoint } = useStore(
      useShallow(state => ({
          breakpoint: state.breakpoint,
          isSoundMuted: state.isSoundMuted
      }))
    )
    const isMobile = useIsMobile()
    const config = useControls('Crystal', {
        meshPhysicalMaterial: false,
        transmissionSampler: false,
        backside: false,
        samples: { value: 10, min: 1, max: 32, step: 1 },
        resolution: { value: 2048, min: 256, max: 2048, step: 256 },
        transmission: { value: 0.79, min: 0, max: 1 },
        roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
        thickness: { value: 3.5, min: 0, max: 10, step: 0.01 },
        ior: { value: 3.16, min: 1, max: 5, step: 0.01 },
        chromaticAberration: { value: 0.06, min: 0, max: 1 },
        anisotropy: { value: 0.1, min: 0, max: 1, step: 0.01 },
        distortion: { value: 0.0, min: 0, max: 1, step: 0.01 },
        distortionScale: { value: 0.3, min: 0.01, max: 1, step: 0.01 },
        temporalDistortion: { value: 0.5, min: 0, max: 1, step: 0.01 },
        clearcoat: { value: 1, min: 0, max: 1 },
        attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
        attenuationColor: '#ffffff',
        color: '#0064d3',
        bg: '#839681',
        cableColor: '#202227'
      }) 

  const getPosition = useCallback(() => {
    switch (breakpoint) {
      case 'medium':
        return [0, viewport.height * -0.18, 0];
      case 'xlarge':
        return [0, viewport.height * -0.3, 0];
      default:
        return [0, viewport.height * -0.3, 0];
    }
  }, [breakpoint, viewport.height])    

  const [{ position, opacity, emissionIntensity }, api] = useSpring(
    () => ({
      scale: 1,
      opacity: 1,
      position: getPosition(),
      rotation: [Math.PI / 6, 0, 0],
      emissionIntensity: 1,
      config: key => {
        switch (key) {
          case 'scale':
            return {
              mass: 4,
              friction: 10,
            }
          case 'position':
            return { 
                mass: 4, 
                friction: 180 
            }
          case 'opacity':
            return { 
                mass: 4, 
                friction: 220
            }
          case 'rotation':
            return { 
                mass: 4, 
                friction: 220,
                easing: easings.easeInOutSine
            }
          default:
            return {}
        }
      }
    }),
    [inViewport]
  )

    const transtionOut = () => {
      api.start({
          opacity: 0,
          position: [0, viewport.height * -1, 0]
      })
    }

    useTranstionToLocation(transtionOut)

    useFrame((_, delta) => {
      if(isRotating) {
        crystal.current.rotation.y += delta * 4;
      }
    })

    const scaleMultiplier = useMemo(() => {
      switch (breakpoint) {
        case 'medium':
          return 1.3;
        case 'xlarge':
          return 1;
        case 'xxlarge':
          return 1;
        default:
          return 1.8;
      }
    }, [breakpoint])

    const handleOnPointerEnter = () => {
      if(!isSoundMuted && audio.paused) fadeIn()
      setIsRotating(true)
      api.start({ 
        scale: 1.1,
        emissionIntensity: 3, 
      })
    }

    const handleOnPointerLeave = () => {
      if(!isSoundMuted && !audio.paused) fadeOut()
      setIsRotating(false)
      api.start({ 
        scale: 1,
        emissionIntensity: 1, 
      })
    }

    return (
        <animated.group scale={scale.xy.min() * scaleMultiplier} position={position}>
            <mesh geometry={nodes.cablesRight.geometry}>
                <meshStandardMaterial color={config.cableColor} metalness={0.5} roughness={1} />
            </mesh>
            <mesh geometry={nodes.cablesLeft.geometry}>
                <meshStandardMaterial color={config.cableColor} metalness={0.5} roughness={1} />
            </mesh>
            <mesh geometry={nodes.ring.geometry}>
                <meshStandardMaterial color="gray" metalness={1} roughness={0.5} />
            </mesh>
            <Float>
                <animated.mesh 
                    ref={crystal}
                    onPointerEnter={!isMobile ? handleOnPointerEnter : null} 
                    onPointerLeave={!isMobile ? handleOnPointerLeave : null} 
                    geometry={nodes.crystal.geometry}
                >
                    <AnimatedMeshTransmissionMaterial {...config} emissive={config.color} emissiveIntensity={emissionIntensity} transparent opacity={opacity}/>
                </animated.mesh>
            </Float>
        </animated.group>
    )
}

const Contact = () => {
    const el = useRef()
    const { shouldTransition } = useTranstionToLocation()

    return (
        <>
            <section id="contact" ref={el} className={classNames(styles.contact, {
                [styles.transition]: shouldTransition
            })}>
                
                <div className={styles.info}>
                  <h1>Want to build something together?</h1>
                </div>
                <Footer />
            </section>
            <UseCanvas>
                <ScrollScene track={el}>
                    {(props) => (
                      <>
                        <Crystal {...props} />
                        <Environment preset="sunset" />
                      </>
                    )}
                </ScrollScene>
            </UseCanvas>
        </>
    )
}

export default Contact