import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/vimeo'
import { CiPlay1, CiPause1  } from "react-icons/ci";
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import useStore from '../../store/useStore'
import styles from './videoPlayer.module.scss'

const playIconProps = {
    size: '3rem',
    color: 'white',
    opacity: '0.5'
}

const VideoPlayer = ({ url, autoplay = false, contrast }) => {
    const [isPlaying, setIsPlaying] = useState(autoplay)
    const [isReady, setIsReady] = useState(false)
    const [showPlayerIcon, setShowPlayerIcon] = useState(false)
    const playerControls = useRef()
    const player = useRef()
    const { isSoundMuted } = useStore(
        useShallow(state => ({
            isSoundMuted: state.isSoundMuted
        }))
    ) 

    useEffect(() => {
        if (isPlaying) {
          setTimeout(() => setShowPlayerIcon(false), 1000);
        } else {
          setShowPlayerIcon(true);
        }
      }, [isPlaying]);

    const toggleIsPlaying = () => {
        setIsPlaying(!isPlaying)
    }

    const handleOnReady = () => {
        console.log('read')
        setIsReady(true)
        // setShowPlayerIcon(true)
    }

    return (
        <div 
            ref={player} 
            className={styles.videoWrapper} 
            onClick={isReady ? toggleIsPlaying : null}
        >
            <ReactPlayer 
                url={url} 
                width="100%"
                height="100%"
                playing={isPlaying}
                muted={isSoundMuted}
                onReady={handleOnReady}
                loop
                style={{ 
                    filter: `contrast(${contrast}%)`,
                    pointerEvents: 'none'
                }} />
                <div ref={playerControls} className={classNames(styles.playIcon, { [styles.visible]: showPlayerIcon, [styles.hidden]: !showPlayerIcon })}>
                    {isPlaying ? <CiPause1 {...playIconProps} /> : <CiPlay1 {...playIconProps} />}
                </div>
                
        </div>
    )
}

export default VideoPlayer