import { ChromaticAberration, EffectComposer, Noise, Scanline, Vignette } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing'
import { useLocation } from 'wouter';
import routes from '../../config/routes';

const Effects = () => {
    const [location] = useLocation()
    const isProjectLocation = location.includes(routes.project.url)
    return (
        <EffectComposer renderPriority={2}>
            <Noise opacity={isProjectLocation ? 0.15 : 0.05} />
            <Vignette eskil darkness={1.1} />
            <ChromaticAberration />
            {isProjectLocation && <Scanline density={0.5} blendFunction={BlendFunction.OVERLAY} opacity={0.2}/> }
        </EffectComposer>
    )
}

export default Effects