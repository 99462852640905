import About from "../About"
import Contact from "../Contact"
import LandingPage from "../LandingPage"
import Projects from "../Projects"
import ScrollRestoration from '../../components/ScrollRestoration';
import styles from './test.module.scss'

const Home = () => (
    <div className={styles.home}>
        <LandingPage />
        <Projects />
        <About />
        <Contact /> 
        <ScrollRestoration />
    </div>
)

export default Home