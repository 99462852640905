import { useEffect } from 'react';
import styles from './cursor.module.scss'
import { useRef } from 'react';

const Cursor = () => {
    const cursor = useRef()

    useEffect(() => {
        const handleMouseMove = (e) => {
            // console.log(e)
            cursor.current.style.top = `${e.clientY}px`
            cursor.current.style.left = `${e.clientX}px`
        }

        const handleMouseClick = (e) => {
            cursor.current.classList.add(styles.expand)

            setTimeout(() => {
                cursor.current.classList.remove(styles.expand)
            }, 500);
        }

        const handleScroll = (e) => {
            /* console.log(window)
            const scrollOffset =  cursor.current.style.top - window.screenY
            cursor.current.style.top = `${cursor.current.style.top + scro}px` */
        }
        window.addEventListener('mousemove', handleMouseMove) 
        window.addEventListener('click', handleMouseClick) 
        window.addEventListener('scroll', handleScroll) 
      return () => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('click', handleMouseClick)
        window.removeEventListener('scroll', handleScroll)
      };
    }, [])
    return <div ref={cursor} />
    // return <div ref={cursor} className={styles.cursor} />
}

export default Cursor