import { useMemo } from 'react';

const useAudioWithFade = (audioUrl, duration = 2000, volume = 1) => {
  const audio = useMemo(() => {
    const audio = new Audio(audioUrl)
    audio.volume = volume
    audio.loop = true
    return audio 
  }, [audioUrl, volume])

  const fadeOut = () => {
    if (audio) {
      const fadeOutInterval = 50;
      const fadeOutSteps = duration / fadeOutInterval;
      const volumeStep = audio.volume / fadeOutSteps;

      const fadeAudio = setInterval(() => {
        if (audio.volume > volumeStep) {
          audio.volume -= volumeStep;
        } else {
          audio.volume = 0;
          clearInterval(fadeAudio);
          audio.pause();
        }
      }, fadeOutInterval);
    }
};

const fadeIn = () => {
    if (audio) {
        audio.currentTime = 0
        audio.volume = 0;
        audio.play();
        const fadeInInterval = 50;
        const fadeInSteps = duration / fadeInInterval;
        const volumeStep = 1 / fadeInSteps;
  
        const fadeAudio = setInterval(() => {
            if (audio.volume < 1 - volumeStep) {
              audio.volume += Math.min(volumeStep, volume);
              
            } else {
              audio.volume = volume;
              clearInterval(fadeAudio);
            }
        }, fadeInInterval);
    }
};

  return {
    audio,
    fadeIn,
    fadeOut
  };
}

export default useAudioWithFade;