export const findProjectBySlug = (projects, slug) => projects.find(project => project.slug === slug)

export const getNextProject = (projects, slug) => {
    const projectIndex = projects.findIndex(project => project.slug === slug)
    if(projectIndex < projects.length - 1) return projects[projectIndex + 1]
    return projects[0]
}

 export const getPreviousProject = (projects, slug) => {
    const projectIndex = projects.findIndex(project => project.slug === slug)
    if(projectIndex > 0) return projects[projectIndex - 1]
    return projects[projects.length - 1]
}