import { Vector3 } from "three";

const routes = {
    landingPage: {
        name: 'Home',
        url: '/',
        initialCameraPosition: new Vector3(0, 0, 10)
    },
    about: {
        name: 'About',
        url: '/about'
    },
    projects: {
        name: 'Projects',
        url: '/projects',
        initialCameraPosition: new Vector3(0.2, 0.8, 1.5)
    },
    project: {
        name: 'Project',
        url: '/project/',
    },
    contact: {
        name: 'Contact',
        url: '/contact'
    }
}

export default routes;