import { useEffect, useRef } from 'react'
import { useLocation, useRoute } from 'wouter'
import styles from './project.module.scss'
import routes from '../../config/routes'
import Block from './Block'
import useStore from '../../store/useStore'
import { findProjectBySlug, getNextProject, getPreviousProject } from '../../utils/findProjectBySlug'
import classNames from 'classnames'
import useTranstionToLocation from '../../hooks/useTransitionToLocation'
import { useShallow } from 'zustand/react/shallow'
import { BsArrowDown } from "react-icons/bs";
import { GrLinkPrevious, GrLinkNext  } from "react-icons/gr";
import RichText from '../../components/RichText'
import VideoPlayer from '../../components/VideoPlayer'
import Footer from '../../components/Footer'
import { useScrollbar } from "@14islands/r3f-scroll-rig"

const iconProps = {
    size: '1.7rem',
    color: 'white',
    opacity: '1'
}

const Project = () => {
    const [, params] = useRoute(routes.project.url + ':slug')
    const [, setLocation] = useLocation()
    const { projects, setIsAmbientAudioActive } = useStore(
        useShallow(state => ({
            projects: state.projects,
            isSoundMuted: state.isSoundMuted,
            setIsAmbientAudioActive: state.setIsAmbientAudioActive
        }))
    ) 
    const project = findProjectBySlug(projects, params?.slug)
    const nextProject = getNextProject(projects, params?.slug)
    const previousProject = getPreviousProject(projects, params?.slug)
    const ref = useRef()
    const { scrollTo } = useScrollbar()

    useEffect(() => {
        setIsAmbientAudioActive(false)
      }, [setIsAmbientAudioActive])

    useEffect(() => {
        scrollTo(0, { immediate: true })
    }, [scrollTo])

    const transitionOut = () => {
        setIsAmbientAudioActive(true)
    }

    const { shouldTransition, setTransitionToLocation } = useTranstionToLocation(transitionOut, 1000)

    const backToProjects = (e) => {
        e.preventDefault()
        setTransitionToLocation(routes.projects.url)
    }

    const test = () => {
        console.log(ref.current.scrollTop)
        ref.current.scrollTo(0, 0)
        setLocation(routes.project.url + nextProject.slug)
    }
    
    if(ref.current) console.log(ref.current.scrollTop)

    return project && (
            <article ref={ref} className={classNames(styles.project, {
                [styles.fadeOut]: shouldTransition,
                [styles.fadeIn]: !shouldTransition
            })}>
                <a className={styles.backLink} href={routes.projects.url}  onClick={(e) => backToProjects(e)}>Back</a>
                {project && (
                    <>
                        <VideoPlayer url={project.vimeoUrl} contrast={project.videoContrast} autoplay />
                        <BsArrowDown className={styles.scrollIcon} {...iconProps} />
                        <div className={styles.blocksWrapper}>
                            <div className={styles.info}>
                                <h1 className={styles.title}>{project.title}</h1>
                                <div>
                                    {project.info && <RichText content={project.info.content} />} 
                                </div>
                                <div className={styles.tags}>{project.tags.map(tag => <span>{`#${tag}`}</span>)}</div>
                            </div>
                            {project.blocks.map(block => <Block key={block.fields.title} {...block.fields} contrast={project.videoContrast} />)}
                            <div className={styles.linkedProjectsWrapper}>
                                <div className={classNames(styles.linkedProject, styles.previous)} onClick={() => setLocation(routes.project.url + previousProject.slug)}>
                                    <div className={styles.direction}>
                                        <GrLinkPrevious />
                                        <p>Previous</p>
                                    </div>
                                    <h2>{previousProject.title}</h2>
                                </div>
                                <div className={classNames(styles.linkedProject, styles.next)} onClick={test}>
                                    <div className={styles.direction}>
                                        <p>Next</p>
                                        <GrLinkNext  />
                                    </div>
                                    <h2>{nextProject.title}</h2>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <Footer />
            </article>   
        )
}

export default Project;