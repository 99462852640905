import { useTracker, useScrollbar } from "@14islands/r3f-scroll-rig"
import RichText from "../../../components/RichText"
import styles from './block.module.scss'
import classNames from "classnames"
import { useEffect, useRef } from "react"
import VideoPlayer from "../../../components/VideoPlayer"
import { useIsMobile } from '../../../store/useStore'

const Block = (props) => {
    const { title, align, richText, image, gallery, contrast, videoUrl } = props
    const blockRef = useRef()
    const textRef = useRef()
    const imgRef = useRef()
    const { scrollState, rect } = useTracker(blockRef)
    const { onScroll } = useScrollbar()
    const isMobile = useIsMobile()

    useEffect(() => {
        return onScroll(() => {
            // textRef.current.style.transform = `translateY(${MathUtils.mapLinear(scrollState.progress, 0, 1, 70, 0)}%)`
            // if(imgRef.current) imgRef.current.style.transform = `translateY(${MathUtils.mapLinear(scrollState.progress, 0, 1, 30, 0)}%)` 
        })
    }, [scrollState, onScroll, rect])

    const getGalleryImagePosition = (index) => {
        if(isMobile) return styles.center;
        const columIndex = (index % 3) + 1;
        const positions = align === 'left' ? [styles.right, styles.center, styles.left] : [styles.left, styles.center, styles.right]
        return positions[columIndex]
    }

    return (
        <section ref={blockRef} className={classNames(styles.block, styles[align])} >
            <div className={styles.content}>
                <div ref={textRef} className={styles.richTextWrapper}>
                    {title && <h2 className={styles.header}>{title}</h2>}
                    {richText && <RichText className={styles.richText} content={richText.content} />}
                </div>
                {image && (
                    <div ref={imgRef} className={styles.imgWrapper} style={{ filter: `contrast(${contrast}%)` }}>
                        <img src={image.fields.file.url} alt={image.fields.file.name} />
                    </div>
                )}
            </div>
            {gallery && (
                <div className={styles.gallery} style={{ filter: `contrast(${contrast}%)` }}>
                    {gallery.map((image, index) => <img className={getGalleryImagePosition(index)} src={image.fields.file.url} alt={image.fields.file.name} />)}
                </div>
            )}
            {videoUrl && <VideoPlayer url={videoUrl} />}
        </section>
    )
}

export default Block