import { useState } from 'react'
import { useLocation } from 'wouter'
import routes from '../../config/routes'
import styles from './navbar.module.scss'
import useStore, { useIsMobile } from '../../store/useStore'
import classNames from 'classnames'
import { ReactComponent as Logo } from '../../assets/logo_white.svg'
import { RiMenu4Fill } from "react-icons/ri";
import SoundControls from "../SoundControls";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

const NavLinks = ({ className, location, onClick }) => (
    <ul className={className}>
        {Object.values(routes).filter(route => route.name !== 'Home' && route.name !== 'Project').map(route => (
            <li key={route.name}>
                <a 
                    className={classNames({ active: route.url === location })} 
                    href={route.url} 
                    onClick={(e) => onClick(e, route.url)}
                >
                    {route.name}
                </a>
            </li>
        ))}
    </ul>
)

const Navbar = () => {
    const [location] = useLocation()
    const setTransitionToLocation = useStore(state => state.setTransitionToLocation)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const isMobile = useIsMobile()

    const handleOnClick = (e, location) => {
        e.preventDefault()
        if(isMobile && location !== routes.landingPage.url) toggleIsMenuOpen()
        setTransitionToLocation(location)
    }

    const toggleIsMenuOpen = () => setIsMenuOpen(!isMenuOpen)

  return (
    <>
        <nav className={styles.navbar}>
            <Logo className={styles.logo} onClick={e => handleOnClick(e, routes.landingPage.url)} />
            <div className={styles.actions}>
                <div className={styles.mobile}>
                    <SoundControls />
                    <button className={styles.menuButton}>
                        <RiMenu4Fill className={styles.menuIcon} onClick={toggleIsMenuOpen} />
                    </button>
                </div>
                <NavLinks className={styles.navlinks} location={location} onClick={handleOnClick} />
            </div>    
        </nav>
        <Drawer
            open={isMenuOpen}
            onClose={toggleIsMenuOpen}
            className={styles.drawer}
            zIndex="1"
        >
            <NavLinks location={location} onClick={handleOnClick} />
        </Drawer>
    </>
  )
}

export default Navbar
