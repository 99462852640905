import { UseCanvas } from '@14islands/r3f-scroll-rig';
import styles from './error.module.scss'
import Button from '../../components/Button';
import { useControls } from 'leva';
import routes from '../../config/routes';
import { animated, easings, useSpring } from '@react-spring/three';
import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const Alert = () => {
    const tetrahedron = useRef()
    const { initialColor, finalColor } = useControls('Error', {
        initialColor: '#ff6b6b',
        finalColor: '#f54c65'
    })

    useFrame((_, delta) => {
        tetrahedron.current.rotation.y += delta * 1.5
        tetrahedron.current.rotation.x += delta * 1.5
    })

    const springs = useSpring({
        from: { scale: 1, color: initialColor },
        to: { scale: 1.5, color: finalColor },
        loop: { reverse: true },
        config: {
            easing: easings.linear,
            friction: 1
        }
    })

    return (
        <animated.mesh ref={tetrahedron} {...springs}>
            <tetrahedronGeometry />
            <animated.meshStandardMaterial color={springs.color} />
        </animated.mesh>
    )
}

const Error = () => {
    return (
        <>
            <div className={styles.error}>
                <p>Something went wrong :(</p>
                <Button onClick={() => window.location.href = routes.landingPage.url }>Restart</Button>
            </div>
            <UseCanvas>
                <Alert />
            </UseCanvas>
        </>
    )
}

export default Error;