import './styles/App.scss';
import '@14islands/r3f-scroll-rig/css'
import React, { useEffect, useRef, useState, Suspense } from 'react'
import { GlobalCanvas, SmoothScrollbar } from '@14islands/r3f-scroll-rig'
import { Route, Switch } from "wouter";
import routes from './config/routes'
import contentfulClient from './config/contentfulClient'
import Project from './pages/Project'
import { Leva } from 'leva';
import { Loader, Preload, useTexture } from '@react-three/drei';
import useStore from './store/useStore';
import { useShallow } from 'zustand/react/shallow';
import Navbar from './components/Navbar';
import useGetBreakpoint from './hooks/useGetBreakpoint';
import Background from './components/Background';
import Cursor from './components/Cursor';
import Effects from './components/Effects/Effects';
import Test from './pages/Test';

export default function App() {
  useGetBreakpoint()
  const eventSource = useRef()
  const [enabled] = useState(true)
  const [smoothTouch] = useState(true)
  const { projects, setProjects } = useStore(
    useShallow(state => ({
        projects: state.projects,
        setProjects: state.setProjects
    }))
  )

  // Preload projects
  useEffect(() => {
    const getProjects = async () => {
        const response = await contentfulClient.getEntries({
            content_type: 'projectList',
            include: 2,
            'fields.title': 'Ordered Projects'
        })
        const projects = response.items[0].fields.projects

        setProjects(projects.map(({ fields }) => {
          // Preload feature video texture
          useTexture.preload(fields.featuredVideo?.fields.file.url)

          // return project
          return fields 
        }))
    }
    
    if(!projects.lenght) getProjects()
}, [projects.lenght, setProjects])

  return (
    <div ref={eventSource}>
      <GlobalCanvas 
        eventSource={eventSource}
        eventPrefix="client"
        globalRender={false}
        scaleMultiplier={0.01}
        camera={{ fov: 33 }}
        style={{ zIndex: -1 }}
      >
          {(globalChildren) => (
            <>
              <ambientLight />
              <Leva hidden />
              <Effects />
              <Background />
              <Suspense fallback={null}>
                {globalChildren}
                <Preload all />
              </Suspense>
            </>
          )}
      </GlobalCanvas>
      <SmoothScrollbar key={smoothTouch} enabled={enabled} config={{ smoothTouch }}>
        {(bind) => (
          <div className='center' {...bind}>
            <Navbar />
            <main>
              <Cursor />
              <Switch>
              {/*  <Route path={routes.landingPage.url} component={LandingPage} />
                <Route path={routes.projects.url} component={Projects} />
                <Route path={routes.contact.url} component={Contact} />
                <Route path={routes.about.url} component={About} />
                <Route path={routes.project.url + ':slug'} component={props => <Project key={window.location.pathname} {...props} />} />
                <Route path="/error" component={Error} />
                <Route component={NotFound} /> */}
                <Route path={routes.project.url + ':slug'} component={props => <Project key={window.location.pathname} {...props} />} />
                <Route path={routes.landingPage.url + '*'} component={Test} />
              </Switch>
            </main>
          </div>
        )}
      </SmoothScrollbar>
      <Loader containerStyles={{ background: 'black' }} />
    </div>
  )
}