import { shaderMaterial } from "@react-three/drei";
import { Texture } from "three";

export const MatrixShader = shaderMaterial(
    {
        uChannel0: new Texture(),
        uChannel1: new Texture(),
        uTime: 0,
        uOpacity: 1,
        uFontSize: 16, 
        uFlowSpeed: 0.05, 
        uWordSpeed: 0.002,
        uResolution: [1024, 1024],
        transparent: true,
        depthTest: false
      },
    `
      precision mediump float;

      void main() {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        gl_Position = projectedPosition;
      }
    `,
    `
      uniform sampler2D uChannel0;
      uniform sampler2D uChannel1;
      uniform float uTime;
      uniform float uOpacity;
      uniform float uFontSize;
      uniform float uFlowSpeed;
      uniform float uWordSpeed;
      uniform vec2 uResolution;

      float words(){
        vec2 UV = mod(gl_FragCoord.xy, uFontSize) / (uFontSize * 16.);
        vec2 noise = floor(fract(texture(uChannel1, gl_FragCoord.xy / (uFontSize * 16.) - UV + uTime * uWordSpeed).xy) * 16.) / 16.;
        return texture(uChannel0, UV + noise).x; 
      }

      vec3 flow(){
        vec2 uv = gl_FragCoord.xy - mod(gl_FragCoord.xy, uFontSize);
        float offset = cos(uv.x * 1.14 + 5.14) + 1.919810;
        float y = fract(gl_FragCoord.y/uResolution.y + offset * 3.14 + uTime * offset * uFlowSpeed);
        return vec3(.0,.2,.9) / (y * 120.);
      }

      void main() {
        #include <tonemapping_fragment>
        #include <colorspace_fragment>

        gl_FragColor = vec4(words() * flow(), uOpacity);
      }
    `
)

export default MatrixShader