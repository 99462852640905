import { PositionalAudio, useVideoTexture } from '@react-three/drei'
// import { animated, useSpring, easings } from '@react-spring/three'
import useStore from '../../store/useStore'
// import { useControls } from 'leva'
import { useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { extend, useFrame } from '@react-three/fiber'
import ScreenShaderMaterial from '../../shaders/screenShader'
import { Clock, AdditiveBlending } from 'three'
import { animated, easings, useSpring } from '@react-spring/three'
import { useEffect, forwardRef } from 'react'
import useTranstionToLocation from '../../hooks/useTransitionToLocation'

extend({ ScreenShaderMaterial })

const Screen = forwardRef(({ name, mesh, onClick, onPointerEnter = () => {}, onPointerLeave = () => {}, featuredImage, featuredVideo }, ref) => {
    const audio = useRef()
    const screenShader = useRef()
    const clock = useRef(new Clock())
    const { activeProjectSlug } = useStore(
        useShallow(state => ({
            activeProjectSlug: state.activeProjectSlug,
            isSoundMuted: state.isSoundMuted
        }))
    )
    // const map = useTexture(featuredImage)
    const video = useVideoTexture(featuredVideo ? featuredVideo : null)
    const [isHover] = useState(false)
    /* const { idleColor } = useControls('Screens', {
        idleColor: '#555555'
    })
    const isActive = activeProjectSlug === name */

    /* const { color } = useSpring({
        color: isActive ? 'white' : idleColor,
        config: { 
            easing: easings.easeInBounce,
            duration: 300
        }
    }) */

/*     const handleOnPointerEnter = (e) => {
        if(audio.current && !isSoundMuted) {
            audio.current.setVolume(0.8)
            audio.current.play() 
        }
        onPointerEnter(e)
        setIsHover(true)
        document.body.style.cursor = 'pointer !important'
        // api.start({ screenOpacity: 1, config: { easing: easings.linear, duration: 200 } })
    }

    const handleOnPointerLeave = (e) => {
        onPointerLeave(e)
        setIsHover(false)
        document.body.style.cursor = 'auto'
        // api.start({ screenOpacity: 0.1, config: { easing: easings.linear } })
    } */

    useFrame(() => {
        if(screenShader.current) {
            screenShader.current.uniforms.uTime.value = clock.current.getElapsedTime();
        }
    })

    const AnimatedScreenShaderMaterial = animated(({...props}) => <screenShaderMaterial ref={screenShader} key={ScreenShaderMaterial.key} uTexture={isHover ? video : video } blending={AdditiveBlending} uOpacity={props.opacity}/>)

    const [{ screenOpacity }, api] = useSpring(() => ({
        from: { screenOpacity: 0 },
        to: { screenOpacity: 0 },
        delay: 1000 + (Math.random() * 1000),
        config: {
            easing: easings.easeInBounce,
            duration: 1000
        }
    }))

    useEffect(() => {
        const isActive = activeProjectSlug === name
        if(isActive) api.start({ screenOpacity: 1, config: { easing: easings.easeInBounce, duration: 1000 } })
        else api.start({ screenOpacity: 0, config: { easing: easings.linear } })
    }, [activeProjectSlug, name, api])

    const transitionOut = () => {
        api.start({ screenOpacity: 0, config: { easing: easings.linear } })
    }

    useTranstionToLocation(transitionOut)

    return (
        <mesh
            ref={ref}
            // onClick={(e) => onClick(e)} 
            // onPointerEnter={handleOnPointerEnter}
            // onPointerLeave={handleOnPointerLeave}
            geometry={ mesh.geometry } 
            position={ mesh.position }
            rotation={ mesh.rotation }
            scale={ mesh.scale }
            name={name}
        >
            <AnimatedScreenShaderMaterial ref={screenShader} opacity={screenOpacity} />
            <PositionalAudio ref={audio} url="screen_on.mp3" loop={false} />
        </mesh> 
    )
})

export default Screen;