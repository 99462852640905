import styles from './skill.module.scss'
import { useInView } from '@react-spring/web'

const Skill = ({name, percentage}) => {
    const [ref, inView] = useInView()

    return (
        <div ref={ref} className={styles.skill}>
            <p>{name}</p>
            <span className={styles.mastery}>
                <span className={styles.percentage} style={{ width: inView ? percentage : '0%' }}/>
            </span>
        </div>
    )
}

export default Skill