import { useControls } from "leva"
import { useRef } from "react"

const Background = () => {
    const background = useRef()
    const { color } = useControls('Background', {
        color: '#0b0d17',
    })
   /*  const { backgroundColor } = useSpring({
        backgroundColor: color,
        config: { duration: 2000 },
    })     */
    
   /*  useFrame(() => {
        if(background.current) background.current = background.current.lerp('red', 0.8)
    }) */

    return <color ref={background} attach="background" args={[color]} />
}

export default Background