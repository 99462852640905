import styles from './about.module.scss'
import classNames from 'classnames'
import useTranstionToLocation from '../../hooks/useTransitionToLocation'
import Skill from './Skill'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const About = () => {
    const { shouldTransition } = useTranstionToLocation(undefined, 1000) 

    return (
        <section id="about" className={styles.about}>
            <div className={classNames(styles.topLine, 'gradient', { [styles.slideOutLeft]: shouldTransition, [styles.slideInLeft]: !shouldTransition })} />
            <div className={classNames(styles.wrapper, { [styles.fadeOut]: shouldTransition, [styles.fadeIn]: !shouldTransition })}>
                <p className={styles.text}>
                I'm Ramses Salas, a Creative Technologist based in Berlin. I love mixing code, animation, interactivity, and generative design to develop engaging experiences. I focus on using technology to build immersive projects that aim to captivate and inspire. Whether it's an application, an interactive piece, or generative art, I enjoy bringing creative ideas to life and creating memorable experiences.
                </p>
                <h2>Stack</h2>
                <div>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                    >
                        <Masonry gutter="3rem">
                        <div className={styles.list}>
                        <div>
                        <h3>Dev Frameworks</h3>
                            <Skill name="React" percentage="100%" />
                            <Skill name="Next.js" percentage="100%" />
                            <Skill name="Webflow" percentage="100%" />
                            <Skill name="Three.js" percentage="80%" />
                            <Skill name="Unity" percentage="60%" />
                            <Skill name="AWS Amplify" percentage="60%" />
                            <Skill name="Laravel" percentage="50%" />
                            <Skill name="Django" percentage="50%" />
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>Programming</h3>
                            <Skill name="Javascript" percentage="100%" />
                            <Skill name="Typescript" percentage="100%" />
                            <Skill name="HTML" percentage="100%" />
                            <Skill name="CSS" percentage="100%" />
                            <Skill name="GLSL" percentage="60%" />
                            <Skill name="GraphQL" percentage="60%" />
                            <Skill name="Python" percentage="50%" />
                            <Skill name="PHP" percentage="50%" />
                            <Skill name="C#" percentage="50%" />
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>Design</h3>
                            <Skill name="Figma" percentage="100%" />
                            <Skill name="Photoshop" percentage="100%" />
                            <Skill name="Illustrator" percentage="80%" /> 
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>3D/2D Graphics</h3>
                            <Skill name="Touch Designer" percentage="80%" />
                            <Skill name="Blender" percentage="70%" />
                            <Skill name="After Effects" percentage="70%" />
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>CAD & Manufacturing</h3>
                            <Skill name="Fusion 360" percentage="50%" />
                            <Skill name="UltiMaker Cura" percentage="50%" />
                            <Skill name="LightBurn" percentage="50%" />
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>Audio & Video</h3>
                            <Skill name="Premiere Pro" percentage="50%" />
                            <Skill name="Audacity" percentage="50%" />
                        </div>
                    </div>
                    <div className={styles.list}>
                        <div>
                        <h3>Others</h3>
                            <Skill name="Git" percentage="100%" />
                        </div>
                    </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
            <div className={classNames(styles.bottomLine, 'gradient', { [styles.slideOutRight]: shouldTransition, [styles.slideInRight]: !shouldTransition })} />
        </section>
    )
}

export default About