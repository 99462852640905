
import { CiVolumeHigh, CiVolumeMute  } from "react-icons/ci";
import useStore from "../../store/useStore";
import { useShallow } from "zustand/react/shallow";
import { useEffect } from "react";
import useAudioWithFade from "../../hooks/useAudioWithFade";
import { useLocation } from "wouter";

const iconProps = {
    size: '24px',
    color: 'white',
    opacity: '0.6'
}

const SoundControls = () => {
    const [location] = useLocation()
    const { audio, fadeIn, fadeOut } = useAudioWithFade('/healing-forest.mp3', 1000, 0.5);
    const { isSoundMuted, setIsSoundMuted, isAmbientAudioActive } = useStore(
        useShallow(state => ({
            isSoundMuted: state.isSoundMuted,
            setIsSoundMuted: state.setIsSoundMuted,
            fadeOutGlobalAudio: state.fadeOutGlobalAudio,
            isAmbientAudioActive: state.isAmbientAudioActive,
        }))
    )

    useEffect(() => {
      isSoundMuted ? audio.pause() : audio.play()
    }, [audio, isSoundMuted, location])

    useEffect(() => {
      if(audio.paused && isAmbientAudioActive && !isSoundMuted) {
        fadeIn()
      } else if(!audio.paused && !isAmbientAudioActive) {
        fadeOut()
      }
    }, [audio, isAmbientAudioActive, isSoundMuted, fadeIn, fadeOut])

    const toggleIsSoundMuted = () => setIsSoundMuted(!isSoundMuted)

  return (
    <button onClick={toggleIsSoundMuted} style={{ cursor: 'pointer' }}>
        {isSoundMuted ? <CiVolumeMute {...iconProps} /> : <CiVolumeHigh {...iconProps} />}
    </button>
  );
}

export default SoundControls
