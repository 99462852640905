import styles from './landingPage.module.scss'
import { useTexture } from "@react-three/drei"
import { animated } from '@react-spring/web'
import { useRef } from "react"
import { ScrollScene, UseCanvas } from "@14islands/r3f-scroll-rig"
import { extend, useFrame } from '@react-three/fiber'
import MatrixShaderMaterial from '../../shaders/matrixShader'
import { Clock, AdditiveBlending } from 'three'
import { useThree } from "@react-three/fiber"

extend({ MatrixShaderMaterial })

const Background = ({ scrollState }) => {
    const matrixShader = useRef()
    const { viewport, size } = useThree()
    const clock = useRef(new Clock())
    const { channel0, channel1 } = useTexture({
        channel0: '/channel0.png',
        channel1: '/channel1.png'
    })

    useFrame(() => {
        if(matrixShader.current) {
            matrixShader.current.uniforms.uTime.value = clock.current.getElapsedTime();
            matrixShader.current.uniforms.uOpacity.value = 1 - scrollState.progress;
        }
    })

    const AnimatedMatrixShaderMaterial = animated(({...props}) => (
        <matrixShaderMaterial 
            ref={matrixShader} 
            key={MatrixShaderMaterial.key} 
            uChannel0={channel0} 
            uChannel1={channel1} 
            blending={AdditiveBlending} 
            uResolution={[size.width, size.height]}
        />
    ))

    return (
        <mesh>
            <planeGeometry args={[viewport.width, viewport.height * 3]} />
            <AnimatedMatrixShaderMaterial ref={matrixShader} />
        </mesh> 
    )
}

const LandingPage = () => {
    const el = useRef()

    return (
        <>
            <div ref={el} className={styles.landingPage}>
                <div className={styles.intro}>
                    <p>Creative Technologist</p>
                    <h1>RAMSES SALAS</h1> 
                </div>
            </div>
            <UseCanvas>
                <ScrollScene track={el}>
                    {(props) => (
                        <Background {...props} />
                    )}
                </ScrollScene>
            </UseCanvas>
        </>
    )
}

export default LandingPage;